import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/activity/master.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //账单-温度卡与饭卡公用一个账单bill/bill.vue
  {
    path: '/bill',
    name: 'Bill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/bill/bill.vue')
    component: resolve => require(['@/views/bill/bill.vue'], resolve)
  },
  //饭卡余额pay/fk/pay.vue
  {
    path: '/account',
    name: 'Pay',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/fk/pay.vue')
    component: resolve => require(['@/views/pay/fk/pay.vue'], resolve)
  },
  //饭卡充值pay/fk/payment.vue
  {
    path: '/accountDetail',
    name: 'Payment',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/fk/payment.vue')
    component: resolve => require(['@/views/pay/fk/payment.vue'], resolve)
  },
  //饭卡明细pay/fk/detail.vue
  {
    path: '/cardDetails',
    name: 'Detail',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/fk/detail.vue')
    component: resolve => require(['@/views/pay/fk/detail.vue'], resolve)
  },
  // 温度卡余额pay/wdk/pay.vue
  {
    path: '/wdkPay',
    name: 'WdkPay',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/wdk/pay.vue')
    component: resolve => require(['@/views/pay/wdk/pay.vue'], resolve)
  },
  // 温度卡充值pay/wdk/payment.vue
  {
    path: '/wdkPayment',
    name: 'WdkPayment',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/wdk/payment.vue')
    component: resolve => require(['@/views/pay/wdk/payment.vue'], resolve)
  },
  //温度卡明细pay/wdk/detail.vue
  {
    path: '/wdkDetail',
    name: 'WdkDetail',
    // component: () => import(/* webpackChunkName: "about" */ '../views/pay/wdk/detail.vue')
    component: resolve => require(['@/views/pay/wdk/detail.vue'], resolve)
  },
  {
    path: '/beauty',
    name: 'Beauty',
    // component: () => import(/* webpackChunkName: "about" */ '../views/activity/beauty.vue')
    component: resolve => require(['@/views/activity/beauty.vue'], resolve)
  },
  //回执单
  {
    path: '/receipt',
    name: 'Receipt',
    // component: () => import(/* webpackChunkName: "about" */ '../views/receipt/index.vue')
    component: resolve => require(['@/views/receipt/index.vue'], resolve)
  },
  //商品详情
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    // component: () => import(/* webpackChunkName: "about" */ '../views/goods/detail/index.vue')
    component: resolve => require(['@/views/goods/detail/index.vue'], resolve),
    meta:{
      title: '商品详情'
    }
  },
]

const router = new VueRouter({
  routes
})

export default router
